import React, {
    createContext,
    useContext,
} from "react";

// Create the context
const FromPageContext = createContext(undefined);

export const useFromPage = () => {
    const context = useContext(FromPageContext);
    if (!context) {
        throw new Error("Context must be used within a useFromPage");
    }
    return context;
};

export const FromPageProvider = ({ children }) => {

    const isPackage = /mypackage/.test(window.location)
    const isAssigningToFan = /assign/.test(window.location)

    return (
        <FromPageContext.Provider
            value={{
                isPackage,
                isAssigningToFan
            }}
        >
            {children}
        </FromPageContext.Provider>
    );
};
