import React from 'react'

import { HolderInfo } from './HolderInfo'

export default function Holders({ packageHolders }) {

    return (
        <div className="list-table list-table--with-inside-borders list-table--with-border list-table--with-bg list-table--with-radius two-col" role="table">
            <div className="flex-row list-table-header" role="rowgroup">
                <div className='list-table-col text-center list-table-col-header list-table-col-header--dark lg' role="columnheader">
                    <span>First name</span>
                </div>
                <div className='list-table-col text-center list-table-col-header list-table-col-header--dark lg' role="columnheader">
                    <span>Last name</span>
                </div>
                <div className="list-table-col list-table-col-header list-table-col-header--dark lg" role="columnheader">
                    <span>Phone number</span>
                </div>
                <div className="list-table-col list-table-col-header list-table-col-header--dark" role="columnheader">
                    <span>Email</span>
                </div>
                <div className="list-table-col list-table-col-header md text-center list-table-col-header--dark" role="columnheader">
                    <span>Fan Profile</span>
                </div>
            </div>
            { packageHolders && packageHolders.data && packageHolders.data.map((holder, index) => <HolderInfo holder={holder} key={index} />)}
        </div>
    );
}