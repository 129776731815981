import React from 'react';

import { formatPhoneNumber } from './../../../../utilities/helpers';

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';

export default function Details({ fan }) {
    let user;
    if (fan.history) {
        user = {
        firstName: fan?.history?.firstName,
        lastName: fan?.history?.lastName,
        email: fan?.history?.email,
        phoneNumber: fan?.history?.phoneNumber,
    }
    }
    
    return (
        <>
            <div className="card-body-heading card-body-heading--sm">
                <Card.Title as="h5" className='card-title-lg'>Details</Card.Title>
            </div>
            <span className='d-block text-upper mb-3 text-muted'>Personal Information</span>
            <Card body className="card--md card--dark">
                <Stack gap={3}>
                    <Row>
                        <Col>
                            <Stack>
                                <small className='fw-normal'>Buyer name</small>
                                <small className='fw-medium'>{user?.firstName} {user?.lastName}</small>
                            </Stack>
                        </Col>
                        <Col>
                            <Stack>
                                <small className='fw-normal'>Email</small>
                                <small className='fw-medium'>{user?.email}</small>
                            </Stack>
                        </Col>
                    </Row>
                    <Row className='mt-0'>
                        <Col>
                            <Stack>
                                <small className='fw-normal'>Phone number</small>
                                <small className='fw-medium'>{formatPhoneNumber(user?.phoneNumber)}</small>
                            </Stack>
                        </Col>
                        <Col>
                            <Stack>
                                <small className='fw-normal'>Member since</small>
                                <small className='fw-medium'></small>
                            </Stack>
                        </Col>
                    </Row>
                </Stack>
            </Card>
        </>
    );
}
