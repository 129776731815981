import React, { useState, useEffect } from 'react';

import { usePagination } from '../../../providers/PaginationProvider/PaginationProvider';

import { getInvoices } from '../../../utilities/api';

import { checkPermission, paymentStatuses } from "../../../utilities/helpers";

import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import { SearchBar } from '../../SearchBar';
import { ExportBtn } from "../../ExportBtn";
import { LoadingContainer } from '../../LoadingContainer';
import { Fans } from './Fans';
import { Pagination } from '../../Pagination';
import { EmptyContainer } from '../../EmptyContainer';

export default function FansList({ packageId, invoices, hasPackageEnded }) {

    const { start, setStart, end, setEnd, currentPage, setCurrentPage, perPage, pageCount, setPageCount, resultsPerPageOpts, handleChange } = usePagination()

    const statusOpts = [
        {
            label: 'All',
            value: 'all'
        },
        ...[...new Map(
            Object.values(paymentStatuses).map(obj => [JSON.stringify(obj), obj])
        ).values()]
    ]

    const [isLoading, setIsLoading] = useState(false)

    const [status, setStatus] = useState(statusOpts[0].value)

    // search query
    const [
        query,
        setQuery
    ] = useState('');

    // invoices with filters applied
    const [filteredInvoices, setFilteredInvoices] = useState(invoices);

    // set total amount of pages and data
    useEffect(() => {
        const end = start + perPage;
        setEnd(end)
        setFilteredInvoices(invoices?.slice(start, end))
        setPageCount(Math.ceil(invoices?.length / perPage))
    }, [invoices, start, perPage])

    useEffect(() => {
        setIsLoading(true)
        getFilteredInvoices()
    }, [query, status])

    const getFilteredInvoices = () => {
        getInvoices(packageId, query, status)
            .then((res) => {
                setFilteredInvoices(res.data.slice(start, end))
                setIsLoading(false)
            })
            .catch((err) => {
                console.error(err)
                setIsLoading(false)
            })
    }

    // Invoke when user click to request another page.
    const handlePageClick = (e) => {
        const newStart = (e.selected * perPage) % invoices?.length;
        setStart(newStart);
        setCurrentPage(e.selected)
    };

    const getText = () => {
        const obj = statusOpts.find(opt => opt.value === status)

        if (obj.value == 'all') {
            return
        } else {
            return `with ${obj.label} status`
        }
    }

    return (
        <>
            <header className='section-header'>
                <div className="actions-group-flex">
                    <FloatingLabel controlId="invoices" id="filter-by-status-label" label="Filter By Status">
                        <Form.Select value={status} onChange={(e) => setStatus(e.target.value)} aria-labelledBy="filter-by-status-label">
                            {statusOpts.map((option, index) => (
                                <option key={index} value={option.value}>{option.label}</option>
                            ))}
                        </Form.Select>
                    </FloatingLabel>
                    <SearchBar getQuery={setQuery} placeholder="Search by phone number or email" />
                    <ExportBtn data={filteredInvoices} exportedData="invoices" />
                </div>
            </header>
            {isLoading ? (
                <LoadingContainer />
            ) : (
                <>
                    {filteredInvoices?.length > 0 ? (
                        <>
                            <Fans packageId={packageId} invoices={filteredInvoices} hasPackageEnded={hasPackageEnded} />
                            <Pagination pageCount={pageCount} perPage={perPage} currentPage={currentPage} resultsPerPageOpts={resultsPerPageOpts} handleClick={handlePageClick} handleChange={handleChange} />
                        </>
                    ) : (
                        <Card body>
                            <EmptyContainer style="center lg">
                                <p>No fans found {getText()}{query && ` matching '${query}'`}. Please try again.</p>
                            </EmptyContainer>
                        </Card>
                    )}
                </>
            )}
        </>
    );
}
