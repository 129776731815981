import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import LoadingContext from '../../context/Loading/Loading';
import PackageDetailsContext from '../../context/PackageDetails/PackageDetails';

import { getInvoices } from '../../utilities/api';

import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

import { NoPermissionsContainer } from '../NoPermissionsContainer';
import { PageLoadingContainer } from '../PageLoadingContainer';
import { EmptyContainer } from '../EmptyContainer';
import { FansList } from './FansList';

export default function AssignPackagesWrapper({ uuid }) {

    const navigate = useNavigate()

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const { updateIsPackagePublished, updateIsPackageOnsale, updateIsPackageSoldout, updateHasPackageEnded, hasPackageEnded } = useContext(PackageDetailsContext)

    const [hasPermission, setHasPermission] = useState(true);
    const [invoices, setInvoices] = useState({})

    useEffect(() => {
        showLoading()
        loadInvoices()
    }, [])

    const loadInvoices = () => {
        getInvoices(uuid, '', 'all')
            .then((res) => {
                setInvoices(res.data)
                // update context
                updateIsPackageOnsale()
                updateHasPackageEnded()
                updateIsPackageSoldout()
                updateIsPackagePublished()
                hideLoading()
            })
            .catch((err) => {
                console.error(err)
                hideLoading()
            })
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <>
                    <div className='position-relative'>
                        <section
                            className={`max-width-wrapper ${!hasPermission ? "overlay" : ""
                                }`}
                        >
                            <section>
                                <header className='section-header section-heading--flex section-heading section-heading--secondary'>
                                    <div className="section-heading">
                                        <h1>Assign Package</h1>
                                        <p className='section-header-desc'>Manually add your fan information for package tickets</p>
                                    </div>
                                    <Button className='btn-plus' variant="outline-light" size="lg" onClick={() => navigate(`/mypackage/${uuid}/seatmap/assign`)} disabled={hasPackageEnded}>Assign package</Button>
                                </header>
                                <section>
                                    {invoices?.length > 0 ? (
                                        <FansList packageId={uuid} invoices={invoices} hasPackageEnded={hasPackageEnded} />
                                    ) : (
                                        <Card body>
                                            <EmptyContainer style='center lg'>
                                                <p>
                                                    No list created
                                                </p>
                                                <p className='subtitle'>
                                                    Click on Assign package above to add your first collection
                                                </p>
                                            </EmptyContainer>
                                        </Card>
                                    )}
                                </section>
                            </section>
                        </section>

                        {!hasPermission && <NoPermissionsContainer />}
                    </div>
                </>
            )}
        </>
    )
}