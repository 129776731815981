import React from "react";
import { Link } from 'react-router-dom';
import { LinkContainer } from "react-router-bootstrap";

import { formatDateTime, getTimezoneDate, timezones, getSinglarOrPluralWord } from "../../../../utilities/helpers";

import Stack from "react-bootstrap/Stack";
import Dropdown from "react-bootstrap/Dropdown";

import { EditDeleteDropdown } from "../../../EditDeleteDropdown";

export default function Package({ eventPackage, handleShow, hasPackageEnded }) {

    let timezone = timezones(eventPackage?.timezone)

    return (
        <li className='package' style={{ backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url(${eventPackage?.image?.url})` }}>
            <Link to={`/mypackage/${eventPackage?.uuid}`}>
                <Stack gap={2} className="p-5">
                    <span className="caption caption-bold caption--uppercase caption--light text-capitalize">{eventPackage?.category?.name}</span>
                    <h5 className="headline-3 m-0">{eventPackage?.name}</h5>
                    <Stack gap={1}>
                        <span className="normal-bold">{eventPackage?.events?.length} {getSinglarOrPluralWord(eventPackage?.events?.length, 'Event')}</span>
                        <span>
                            <span>{formatDateTime(getTimezoneDate(eventPackage?.start, timezone), 'dateOnly')}</span>
                            <span className="time">{formatDateTime(getTimezoneDate(eventPackage?.start, timezone), 'timeOnly')}</span>
                        </span>
                    </Stack>
                </Stack>
            </Link>
            <EditDeleteDropdown canEdit={false} canDelete={!hasPackageEnded} handleShow={() => handleShow(eventPackage?.id)}>
                <li id="dropdown-item-manage">
                    <LinkContainer to={`/mypackage/${eventPackage?.uuid}`}>
                        <Dropdown.Item className="btn-view">Manage</Dropdown.Item>
                    </LinkContainer>
                </li>
            </EditDeleteDropdown>
        </li>
    )
}