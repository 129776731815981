import React from 'react';

import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';

import { InfoIcon } from '../../InfoIcon';
import { UploadImage } from '../../UploadImage';
import { TrashButton } from '../../TrashButton';
import { RequiredText } from '../../RequiredText';

export default function Details({ eventPackage, categories, image, setImage, coordinates, setCoordinates, handleChange, requiredFieldErrorStatus, isEditable }) {

    const handleRemove = () => {
        setImage()
    }

    return (
        <>
            <Form.Group className="form-group" controlId="name">
                <div className="form-label--flex">
                    <Form.Label>Name</Form.Label>
                    <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip>Maximum 100 characters. No HTML or emoji allowed</Tooltip>}>
                        <Button variant="link">
                            <InfoIcon />
                        </Button>
                    </OverlayTrigger>
                    {requiredFieldErrorStatus?.name && (<RequiredText />)}
                </div>
                <Form.Control
                    type="text"
                    name="name"
                    disabled={isEditable}
                    placeholder='Enter package name'
                    value={eventPackage?.name}
                    onChange={handleChange}
                    required
                    autoFocus
                    className={requiredFieldErrorStatus?.name ? 'error-border' : ''}
                />
            </Form.Group>
            <Form.Group className="form-group" controlId="category">
                <div className="form-label--flex">
                    <Form.Label>Category</Form.Label>
                    {requiredFieldErrorStatus?.category && (<RequiredText />)}
                </div>
                <Form.Select
                    name="category"
                    onChange={handleChange}
                    value={eventPackage?.category || ''}
                    required
                    className={`text-capitalize ${requiredFieldErrorStatus?.category ? 'error-border' : ''} `}
                    disabled={isEditable}
                >
                    <option disabled hidden value="">Select Category</option>
                    {categories?.map((cat, i) => (
                        <option className="text-capitalize" key={i} value={cat?.id}>{cat?.name}</option>
                    ))}
                </Form.Select>
            </Form.Group>
            <Form.Group className="form-group" controlId="thumbnail">
                <div className="form-label--flex">
                    <Form.Label>Thumbnail</Form.Label>
                    {requiredFieldErrorStatus?.image && (<RequiredText />)}
                </div>
                <div className={`${image ? 'flex' : ''} ${requiredFieldErrorStatus?.image ? "error-border error-border--with-radius" : ''}`}>
                    <UploadImage dropzoneSize="sm" dropzoneWithRadius={true} selectedImage={image} setSelectedImage={setImage} coordinates={coordinates} setCoordinates={setCoordinates} size="md" canCrop={false} altText={`Cover art for ${eventPackage?.name} package`} />
                    {image && (
                        <TrashButton
                            onClick={handleRemove}
                            isDisabled={isEditable}
                        />
                    )}
                </div>
            </Form.Group>
            <Form.Group className="form-group" controlId="description">
                <div className="form-label--flex">
                    <Form.Label>Description</Form.Label>
                    <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip>Add information about this package</Tooltip>}>
                        <Button variant="link">
                            <InfoIcon />
                        </Button>
                    </OverlayTrigger>
                </div>
                <Form.Control
                    as="textarea"
                    rows={5}
                    name="description"
                    placeholder="Add description for this package"
                    onChange={handleChange}
                    value={eventPackage?.description || ''}
                    disabled={isEditable}
                />
            </Form.Group>
        </>
    );
}
