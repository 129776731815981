import React, { useState, useEffect } from "react"

import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { AddEventsModal } from "./AddEventsModal";
import { Event } from "./Event";
import { TrashButton } from "../../TrashButton";
import { DeleteModal } from "../../DeleteModal";
import { RequiredText } from "../../RequiredText";

import './events-list.scss'

export default function EventsList({ allEvents, timezone, selectedEventIds, setSelectedEventIds, requiredFieldErrorStatus, isLoading, isDisabled }) {

    const [show, setShow] = useState(false)

    const [showDelete, setShowDelete] = useState(false)

    const [initialSelectedIds, setInitialSelectedIds] = useState([])

    const [selectedIds, setSelectedIds] = useState([])

    // event id to remove 
    const [id, setId] = useState()

    useEffect(() => {
        setSelectedIds(selectedEventIds)
        setInitialSelectedIds(selectedEventIds)
    }, [selectedEventIds])

    const handleShow = () => setShow(true)

    const handleClose = () => setShow(false)

    const handleCancel = () => {
        setSelectedIds(selectedEventIds)
        handleClose()
    }

    const handleCloseDelete = () => setShowDelete(false)

    const handleCheck = (id) => {
        console.log(id);
        if (selectedIds.includes(Number(id))) {
            setSelectedIds(selectedIds.filter(idx => idx !== Number(id)))
        } else {
            setSelectedIds([...selectedIds, Number(id)])
        }
    }

    const handleSelectAll = () => {
        if (selectedIds.length === allEvents?.length) {
            setSelectedIds([]); // Deselect all
        } else {
            setSelectedIds(allEvents?.map((event) => Number(event.id))); // Select all
        }
    };

    const isChecked = (id) => {
        return selectedIds.includes(Number(id))
    }

    const checker = (arr) => {
        return arr.every(evt => selectedIds.includes(evt.id))
    }

    const handleAdd = () => {
        setSelectedEventIds(selectedIds)
        handleClose()
        setInitialSelectedIds(selectedIds)
    }

    const handleClick = (id) => {
        setId(id)
        setShowDelete(true)
    }

    const handleRemove = () => {
        handleCloseDelete()
        const updatedIds = selectedIds?.filter(s => s !== id)
        setSelectedIds(updatedIds)
        setSelectedEventIds(updatedIds)
        setInitialSelectedIds(updatedIds)
        setId()
    }

    const checkIsSameAsInitial = () => {
        return (initialSelectedIds?.length === selectedIds?.length && initialSelectedIds?.every(id => selectedIds.includes(id)))
    }

    const findAndRemoveId = (events, ids) => {
        // Find the first matching event
        const event = events.find(event => ids.includes(event.id));
        if (event) {
            // Remove the matched ID from the array
            ids = ids.filter(id => id !== event.id);
        }
        return { event, updatedIds: ids };
    }

    // Immediately invoked function expression to find the next selected event according to the sorted allEvents array  
    const remainingSelectedIds = (() => {
        if (selectedEventIds?.length === 0) return;

        let remainingIds = [...selectedEventIds]; // Create a copy to avoid direct modification
        console.log('remaining', remainingIds);
        return selectedEventIds.map(id => {
            // Find the event corresponding to the id and get the updated remainingIds
            const { event, updatedIds } = findAndRemoveId(allEvents, remainingIds);
            remainingIds = updatedIds; // Update remainingIds for the next iteration
            return event;
        });
    })();

    return (
        <>
            <div className="card-body-heading card-body-heading--sm card-body-heading--flex">
                <Card.Title as="h5">Events</Card.Title>
                {requiredFieldErrorStatus?.events && (<RequiredText />)}
            </div>
            <div className="card-body-heading card-body-heading--sm card-body-heading--flex card-body-heading--flex-space-between">
                <div>
                    <Card.Title as="h5">Total Events ({selectedEventIds?.length})</Card.Title>
                    <Card.Subtitle as="h6">Auto-population of regular season events upon schedule announcement.</Card.Subtitle>
                </div>
                {selectedEventIds?.length > 0 && (
                    <Button variant="outline-light" className="btn-plus" onClick={handleShow} disabled={isDisabled}>Add events</Button>
                )}
            </div>
            {remainingSelectedIds?.length > 0 ? (
                <Stack as="ul" className="events-container events-list">
                    {remainingSelectedIds.map((event, idx) => {
                        return (
                            <li key={idx} className="list-item list-item-lg list-item--light">
                                <Event event={event} timezone={timezone}>
                                    <TrashButton onClick={() => handleClick(event?.id)} isDisabled={isDisabled} />
                                </Event>
                            </li>
                        )
                    })}
                </Stack>
            ) : (
                <Stack className={`btn-container ${requiredFieldErrorStatus?.events ? "error-border" : ''} `}>
                    <Button variant="default" className="flex w-100 justify-content-center align-items-center" onClick={handleShow}>
                    </Button>
                </Stack>
            )}

            <AddEventsModal show={show} isLoading={isLoading} events={allEvents} timezone={timezone} isSameAsInitial={checkIsSameAsInitial()} selected={selectedIds} isChecked={isChecked} handleCheck={handleCheck} handleSelectAll={handleSelectAll} checker={checker} handleAdd={handleAdd} handleClose={handleClose} handleCancel={handleCancel} />

            <DeleteModal show={showDelete} entity="event from the package" showText={false} handleClose={handleCloseDelete} handleDelete={handleRemove} />
        </>
    )
}