import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';

import LoadingContext from '../../../context/Loading/Loading';

import Card from 'react-bootstrap/Card'
import Stack from 'react-bootstrap/Stack'

import { PageLoadingContainer } from '../../PageLoadingContainer';
import { BackButton } from '../../BackButton';
import { Details } from './Details';
import { TicketsPayments } from './TicketsPayments';
import { History } from './History';
import { getFanProfile } from '../../../utilities/api';

export default function FanProfileWrapper({ id }) {

    const [isLoading, setIsLoading] = useState(false)

    const [fan, setFan] = useState({})

    useLayoutEffect(() => {
        const navItem = document.getElementById('package-holders-nav-item')

        navItem.classList.add('active')

        return () => {
            navItem.classList.remove('active')
        }
    }, [])

    useEffect(() => {
        setIsLoading(true)
        loadFanProfile()
    }, [])

    const loadFanProfile = () => {
        getFanProfile(id)
            .then((res) => {
                setFan(res.data)
                setIsLoading(false)
            })
            .catch((err) => {
                console.error(err)
                setIsLoading(false)
            })
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <>
                    <Stack gap={5}>
                        <div className="align-self-start">
                            <BackButton size="sm" />
                        </div>
                        <header className='section-header mb-0 section-heading section-heading--secondary'>
                            <div className="section-heading">
                                <h1>Fan profile</h1>
                            </div>
                        </header>
                        <Card body className='card--md card--light'>
                            <Details fan={fan} />
                        </Card>
                        <Card body className='card--md card--light'>
                            <TicketsPayments data={fan} />
                        </Card>
                        {/* <Card body className='card--md card--light'>
                            <History history={fan} />
                        </Card> */}
                    </Stack>
                </>
            )}
        </>
    )
}