import React, { useState, useEffect } from 'react';

import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { RequiredText } from '../../RequiredText';

export default function TicketLimits({ eventPackage, handleChange, handleValid, errors, findError, requiredFieldErrorStatus, isEditable }) {

    const [minQuantityError, setMinQuantityError] = useState()

    const [maxQuantityError, setMaxQuantityError] = useState()

    useEffect(() => {
        setMinQuantityError(findError('minQuantity'))
        setMaxQuantityError(findError('maxQuantity'))
    }, [errors])

    return (
        <>
            <div className="card-body-heading card-body-heading--sm">
                <Card.Title as="h5">Ticket Limits</Card.Title>
                <Card.Subtitle as="h6">Specify a minimum and maximum ticket quantity for this package</Card.Subtitle>
            </div>
            <Row>
                <Col>
                    <Form.Group className='form-group' controlId='minQuantity'>
                        <div className="form-label--flex">
                            <Form.Label>Min Quantity</Form.Label>
                            {requiredFieldErrorStatus?.minQuantity && (<RequiredText />)}
                        </div>
                        <Form.Control
                            placeholder="Minimum quantity"
                            name="minQuantity"
                            aria-describedby="minimum quantity"
                            pattern="^[0-9]*$"
                            value={eventPackage?.minQuantity || ''}
                            onChange={(e) =>
                                handleChange(
                                    (e.target.value === '' || e.target.validity.valid
                                        ? e
                                        : eventPackage?.minQuantity)
                                )
                            }
                            onBlur={handleValid}
                            className={minQuantityError || requiredFieldErrorStatus?.minQuantity ? 'error-border' : ''}
                            required
                            disabled={isEditable}
                        />
                        {minQuantityError && (
                            <Form.Text className='error'>{minQuantityError?.message}</Form.Text>
                        )}
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className='form-group' controlId='maxQuantity'>
                        <div className="form-label--flex">
                            <Form.Label>Max Quantity</Form.Label>
                            {requiredFieldErrorStatus?.maxQuantity && (<RequiredText />)}
                        </div>
                        <Form.Control
                            placeholder="Maximum quantity"
                            name="maxQuantity"
                            aria-describedby="maximum quantity"
                            pattern="^[0-9]*$"
                            value={eventPackage?.maxQuantity}
                            onChange={(e) =>
                                handleChange(e.target.value === '' || e.target.validity.valid
                                    ? e
                                    : eventPackage?.maxQuantity
                                )
                            }
                            onBlur={handleValid}
                            className={maxQuantityError || requiredFieldErrorStatus?.maxQuantity ? 'error-border' : ''}
                            required
                            disabled={isEditable}
                        />
                        {maxQuantityError && (
                            <Form.Text className='error'>{maxQuantityError?.message}</Form.Text>
                        )}
                    </Form.Group>
                </Col>
            </Row>
        </>
    )
}