import React, { useEffect, useState } from 'react';
import moment from 'moment'
import { isEqual } from 'lodash'

import { formatDateTime, getTimezoneDate, getFormattedTimezoneDate, getIsTimeAfterStartErrorMsg, getIsTimeBeforeEventVisibilityErrorMsg, isTimeAfterStart, isTimeBeforeEventVisibility, isISOString } from "../../../../utilities/helpers";

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { Period } from './Period';
import { CreatePeriodModal } from './CreatePeriodModal';
import { GeneralOnsaleModal } from '../../../GeneralOnsaleModal';
import { DeleteModal } from '../../../DeleteModal';
import { RequiredText } from '../../../RequiredText';
import { MatchWarningError } from './MatchWarningError';

export default function Availability({ openOffers, isStandard, inventoryType, event, offers, offer, setOffer, selected, setSelected, isEditing, canEdit, isPeriodMatchingStandardOffer, setIsPeriodMatchingStandardOffer, setIsExactMatch, hasExactMatchError, setHasExactMatchError, hasInBetweenMatchError, setHasInBetweenMatchError }) {

    const [show, setShow] = useState(false)

    const [showGeneralOnsale, setShowGeneralOnsale] = useState(false)

    const [showDelete, setShowDelete] = useState(false)

    const [periods, setPeriods] = useState([])

    // period id used for editing and deleting 
    const [id, setId] = useState()

    // single period for viewing and editing 
    const [period, setPeriod] = useState()

    const [initialState, setInitialState] = useState()

    const [name, setName] = useState('');

    const [hasGeneralOnsalePeriod, setHasGeneralOnsalePeriod] = useState(false)

    const [generalOnsale, setGeneralOnsale] = useState(null);

    const [starts, setStarts] = useState(null)

    const [ends, setEnds] = useState(null)

    const [generalOnsaleError, setGeneralOnsaleError] = useState(false)

    const [startError, setStartError] = useState(false)

    const [endError, setEndError] = useState(false)

    const [errorMsg, setErrorMsg] = useState('')

    const [endErrorMsg, setEndErrorMsg] = useState('')

    // selected/new period matches other open offer period except for standard offer availability period
    const [isPeriodMatchingOtherOffer, setIsPeriodMatchingOtherOffer] = useState(false)

    // offers that are overlapping current offer 
    const [matchedOffers, setMatchedOffers] = useState([])

    // whether off will overlap with standard admission  
    const [willOverlapStandardAdmission, setWillOverlapStandardAdmission] = useState(false)

    useEffect(() => {
        setInitialState({ name: period?.name || "", starts: period?.starts || null, ends: period?.ends || null })
    }, [period])

    useEffect(() => {
        setPeriods(offer?.availabilityPeriods)
    }, [offer])

    // whether offers are overlapping other offers
    useEffect(() => {
        console.log(selected);
        // if can edit (offer is not onsale yet) 
        if (canEdit) {
            // if inventory is open, check availability period 
            if (inventoryType === 'open') {
                resetMatchState()
                // create period modal is open
                if (show) {
                    if (starts && ends) {
                        const period = { starts, ends }
                        checkIfPeriodMatchesOtherOrStandardOffer(period, openOffers)
                    }
                } else {
                    const period = offer?.availabilityPeriods?.find(period => period.id === selected)
                    console.log(period);
                    checkIfPeriodMatchesOtherOrStandardOffer(period, openOffers)
                }
            } else {
                resetMatchState()
            }
        }
    }, [isEditing, offers, inventoryType, show, starts, ends, periods, selected, openOffers])

    useEffect(() => {
        if (isEditing) {
            // set only active period
            setPeriod(periods?.find(period => period.active))
        }
    }, [periods])

    // validation for general onsale date
    // - cannot be after event start time 
    // - cannot be before event visibility
    useEffect(() => {
        setGeneralOnsaleError(isTimeAfterStart(generalOnsale, event) || isTimeBeforeEventVisibility(generalOnsale, event))
        setErrorMsg(getErrorMsg())
    }, [generalOnsale, event])

    // validation for start date
    // - cannot be after event start time 
    // - cannot be before event visibility
    // validation for end date
    // - cannot be after event end time
    // - cannot be before/same as start time 
    useEffect(() => {
        setStartError(isTimeAfterStart(starts, event) || isTimeBeforeEventVisibility(starts, event))
        setEndError(isEndAfterEventEnd(ends) || moment(ends).isSameOrBefore(moment(starts)))
        setErrorMsg(getErrorMsg())
        setEndErrorMsg(getIsEndBeforeOrSameAsStartErrorMsg() || getIsEndAfterEventEndErrorMsg(ends) || '')
    }, [starts, ends, event])

    useEffect(() => {

    }, [offer])

    // validation for start date/general onsale 
    // - cannot be before event visibility
    // - cannot be after event start time 
    const getErrorMsg = () => {
        if (isTimeBeforeEventVisibility(starts, event) || isTimeBeforeEventVisibility(generalOnsale, event)) {
            return getIsTimeBeforeEventVisibilityErrorMsg(event)
        } else if (isTimeAfterStart(starts, event) || isTimeAfterStart(generalOnsale, event)) {
            return getIsTimeAfterStartErrorMsg(event)
        }
        else return ''
    }

    // validation for end date
    // - cannot be before/same as start time 
    const getIsEndBeforeOrSameAsStartErrorMsg = () => {
        if (moment(ends).isSameOrBefore(moment(starts))) {
            return 'Time must be after start time'
        }
    }

    // end date cannot be after event end time (4 hours after event start time)
    const isEndAfterEventEnd = (date) => {
        return moment(date)?.isAfter(moment(getFormattedTimezoneDate(event?.end, event?.timezone)))
    }

    // validation for end date
    // - cannot be before/same as start time 
    const getIsEndAfterEventEndErrorMsg = () => {
        if (isEndAfterEventEnd(ends)) {
            return `Time cannot be after event end time (${formatDateTime(getTimezoneDate(event?.end, event?.timezone), 'timeOnly')})`
        }
    }

    // reusable matching avaliability period logic 
    const checkIfPeriodMatchesOtherOrStandardOffer = (period, openOffers) => {
        const isMatchingOtherOffer = doesMatchOtherOfferPeriod(openOffers, period)
        // period matches with other offer
        if (isMatchingOtherOffer) {
            // check if exact match with other offer 
            const isExactMatchWithOtherOffer = doesMatchOtherOfferPeriod(openOffers, period, true)
            // check if in between match with other offer
            const isBetweenMatchWithOtherOffer = doesMatchOtherOfferPeriod(openOffers, period, false, true)

            if (isExactMatchWithOtherOffer) {
                // can't be exact match 
                setHasExactMatchError(true)
                setIsPeriodMatchingOtherOffer(isMatchingOtherOffer)
            } else if (isBetweenMatchWithOtherOffer) {
                // if not exact match with other offer - in between match with other offer
                // get all matched offers 
                let matchedOffers = getInBetweenMatchedOffers(getOpenOffers(openOffers), period)
                // check to see if offer is Standard Admission is in matched array 
                const doesIncludeStandardOffer = matchedOffers?.some(offer => offer?.id === openOffers[0]?.id)
                console.log(doesIncludeStandardOffer);
                if (doesIncludeStandardOffer) {
                    // if matched array only has one index - only matched on Standard Admission offer
                    if (matchedOffers?.length === 1) {
                        console.log('in between standard');
                        // offer can overlap 
                        getInBetweenStandardOfferMatch(false, true, period, matchedOffers)
                    } else {
                        // is overlapping other offers (including Standard Admission)
                        // remove Standard Admission
                        matchedOffers = matchedOffers?.filter(offer => (offer.id !== openOffers[0]?.id))

                        // cannot be overlapping with another offer
                        setHasInBetweenMatchError(true)
                        setMatchedOffers(matchedOffers)
                    }
                } else {
                    // is overlapping other offers (not including Standard Admission)
                    // cannot be overlapping with another offer
                    setHasInBetweenMatchError(true)
                    setMatchedOffers(matchedOffers)

                }
                setIsPeriodMatchingOtherOffer(isMatchingOtherOffer)
            }
        } else {
            // if offer does not match other offers availability period, check if offer matches standard offer availability periods
            setIsPeriodMatchingStandardOffer(doesMatchStandardPeriod(period))
            const isInBetweenMatchWithStandardOffer = doesMatchStandardPeriod(period, false, true)
            const isExactMatchWithStandardOffer = doesMatchStandardPeriod(period, true)
            if (isExactMatchWithStandardOffer) {
                setHasExactMatchError(true)
            }
            getInBetweenStandardOfferMatch(isExactMatchWithStandardOffer, isInBetweenMatchWithStandardOffer, period)

        }
    }

    const getInBetweenStandardOfferMatch = (isExactMatch, isInBetweenMatch, period, matchedOffers) => {
        if (!isExactMatch) {
            setWillOverlapStandardAdmission(isInBetweenMatch)
            if (!matchedOffers) {
                matchedOffers = getInBetweenMatchedOffers(getOpenOffers(openOffers), period)
            }
            setMatchedOffers(matchedOffers)
        }
    }

    const handleShowCreatePeriod = () => {
        setShow(true)
        resetMatchState()
    }

    const handleShow = (_, period) => {
        if (period) {
            if (period?.name?.includes("General On-sale")) {
                setShowGeneralOnsale(true)
                setHasGeneralOnsalePeriod(true)
                setGeneralOnsale(new Date(getFormattedTimezoneDate(period?.starts, event?.timezone)))
            } else {
                handleShowCreatePeriod()
                setId(period?.id)
                setPeriod(period)
                setName(period?.name)
                setStarts(new Date(getFormattedTimezoneDate(period?.starts, event?.timezone)))
                setEnds(new Date(getFormattedTimezoneDate(period?.ends, event?.timezone)))
            }
        } else handleShowCreatePeriod()
    }

    // create new/edit period
    const handleSubmit = (e, isUpdate = false) => {
        console.log(isUpdate);
        e.preventDefault();
        e.stopPropagation();
        if (!name || !starts || !ends) return
        const periodData = {
            name,
            starts: getTimezoneDate(starts, event?.timezone, true).format(),
            ends: getTimezoneDate(ends, event?.timezone, true).format(),
        }
        // edit period
        if (isUpdate) {
            const updatedPeriod = { ...period, ...periodData }
            const periodIndex = periods?.findIndex(period => period.id === selected)
            console.log(periodIndex);
            setOffer({
                ...offer,
                availabilityPeriods: offer.availabilityPeriods.map((period, index) =>
                    index === periodIndex ? updatedPeriod : period
                )
            });
        } else {
            // create new period
            const newPeriod = {
                ...periodData,
                id: Math.max(...offer.availabilityPeriods.map(period => period.id)) + 1,
                active: true,
            }

            // add latest period created to offer's existing periods 
            setOffer({ ...offer, availabilityPeriods: [...offer.availabilityPeriods, newPeriod] })

            setSelected(newPeriod.id)
        }

        handleClose()
    }

    const resetMatchState = () => {
        setIsPeriodMatchingStandardOffer(false)
        setIsPeriodMatchingOtherOffer(false)
        setIsExactMatch(false)
        setMatchedOffers([])
        setHasExactMatchError(false)
        setHasInBetweenMatchError(false)
        setWillOverlapStandardAdmission(false)
    }

    // close create new period
    const handleClose = () => {
        setName("")
        setStarts(null)
        setEnds(null)
        setShow(false)
        setId()
        resetMatchState()
        if (!isEditing) {
            setPeriod()
        }
    }

    // TODO: edit general onsale period 
    const handleSubmitGeneralOnsale = () => {

    }

    // close general onsale modal 
    const handleCloseGeneralOnsale = () => {
        setGeneralOnsale(null)
        setHasGeneralOnsalePeriod(false)
        setShowGeneralOnsale(false)
    }

    const handleShowDelete = (id) => {
        setId(id)
        setShowDelete(true)
    }

    const handleCloseDelete = () => {
        setId()
        setShowDelete(false)
    }

    // remove availability period
    const handleDelete = () => {
        const updatedPeriods = periods?.filter(period => period.id !== id)

        // if period to delete is selected, change the default selected to the first period
        if (id === selected) {
            setSelected(periods[0]?.id)
        }

        setOffer({ ...offer, availabilityPeriods: updatedPeriods })

        handleCloseDelete()
    }

    const getText = () => {

        if (canEdit) {
            if (isEditing) return 'Edit availability period'
            else return 'Select or create an availability period'
        }
        else return 'Availability period'
    }


    const checkIsDisabled = () => {
        const formattedInitialStarts = new Date(getFormattedTimezoneDate(initialState?.starts, event?.timezone));
        console.log(formattedInitialStarts, new Date(starts));
        const formattedInitialEnds = new Date(getFormattedTimezoneDate(initialState?.ends, event?.timezone));

        const hasEmptyFields = !name || !starts || !ends;
        const hasError = startError || endError;
        const isDateEqual = (date1, date2) => isEqual(date1, new Date(date2));
        const isFormattedDateEqual = (date, formattedInitialDate) => isEqual(new Date(date), formattedInitialDate);

        // all fields have to be filled in 
        const newPeriodChanges = name !== initialState?.name && !isDateEqual(starts, initialState?.starts) &&
            !isDateEqual(ends, initialState?.ends)

        // any field can be changed
        const updatedPeriodChanges = name !== initialState?.name || !isFormattedDateEqual(starts, formattedInitialStarts) ||
            !isDateEqual(ends, formattedInitialEnds)

        const isDisabled = (hasChanges) => {

            // If changes exist but there are empty fields or errors, return true
            if (hasChanges) {
                return hasEmptyFields || hasError || hasExactMatchError || hasInBetweenMatchError;
            }

            // If no changes, disable the update (return true)
            return true;
        }

        return isDisabled(period ? updatedPeriodChanges : newPeriodChanges)
    }

    const getInBetweenMatchedOffers = (offers, period) => {
        console.log(offers, period);
        let matchedOffers = [];

        offers?.map((offer) => {
            if (isExactOrInBetweenMatch(offer, period, false, true)) {
                matchedOffers.push(offer)
            }
        })

        return matchedOffers
    }

    const doesMatchOtherOfferPeriod = (openOffers, period, onlyExact = false, onlyBetween = false) => {
        if (openOffers && openOffers?.length === 0) return

        console.log(openOffers);
        // all open offers except Standard Admission offer and any ended offers 
        openOffers = openOffers
            ?.slice(1) // Remove the first offer
            ?.filter(offer => {
                const activePeriod = offer.availability.find(period => period.active);
                // Keep the offer if it has an active period and hasn't ended
                return activePeriod && moment(getTimezoneDate(activePeriod.ends, event?.timezone)).isSameOrAfter(getTimezoneDate(moment(), event?.timezone))
            });

        if (openOffers?.length === 0) return false

        console.log(openOffers);

        // if editing, get all open offers that is not the offer that is editing  
        if (isEditing) openOffers = openOffers?.filter(openOffer => openOffer.id !== offer?.id)

        console.log(openOffers);

        openOffers = openOffers?.map(offer => {
            const activePeriod = offer?.availability.find(period => period.active)
            return {
                name: offer?.name,
                id: offer?.id,
                period: {
                    starts: activePeriod?.starts,
                    ends: activePeriod?.ends
                }
            }
        })

        return openOffers?.some(offer => {
            return isExactOrInBetweenMatch(offer, period, onlyExact, onlyBetween)
        })
    }

    const doesMatchStandardPeriod = (period, onlyExact = false, onlyBetween = false) => {
        console.log(period);
        if (offers) {
            // if offer editing is Standard Admission 
            if (isEditing && isStandard) return false

            // standard offer 
            const standardAdmissionOffer = offers[0]

            // get active period
            const activePeriod = standardAdmissionOffer.availability.find(period => period.active)

            const standardOffer = {
                period: activePeriod,
                name: standardAdmissionOffer?.name,
                id: standardAdmissionOffer?.id
            }

            if (standardAdmissionOffer) {
                console.log(period, standardOffer);

                return isExactOrInBetweenMatch(standardOffer, period, onlyExact, onlyBetween)
            }
        }
    }

    const isExactOrInBetweenMatch = (offer, period, onlyExact, onlyBetween = false) => {
        console.log(offer, period, onlyExact, onlyBetween);

        // date can be string date (convert to timezone) or date object 
        const getPeriodDate = (date) => {
            if (isISOString(date) || typeof date === 'string') {
                return getFormattedTimezoneDate(date, event.timezone)
            }
            else {
                return date
            }
        }

        // between offer (standard or othe open offer) period start and end dates (overlap in between)
        // or start is before or same as period start and end is after offer period start date - overlaps offer period by end (overlap in end)
        // or start is before period end and end is after or same offer period end - overlaps offer period by start (overlap in start)
        const isInBetweenMatch = offer => {
            const offerPeriod = offer?.period;
            const offerPeriodStart = moment(getFormattedTimezoneDate(offerPeriod?.starts, event?.timezone));
            const offerPeriodEnd = moment(getFormattedTimezoneDate(offerPeriod?.ends, event?.timezone));
            const periodStart = moment(getPeriodDate(period?.starts));
            const periodEnd = moment(getPeriodDate(period?.ends));
            const isBetween = (periodStart.isAfter(offerPeriodStart) && periodEnd.isBefore(offerPeriodEnd)) ||
                (periodStart.isSameOrBefore(offerPeriodStart) && periodEnd.isAfter(offerPeriodStart)) ||
                (periodStart.isBefore(offerPeriodEnd) && periodEnd.isSameOrAfter(offerPeriodEnd))
            return isBetween
        }

        // start and end dates are the same as offer (standard or othe open offer) period start and end dates 
        const isExactMatch = offer => {
            const offerPeriod = offer?.period;
            const offerPeriodStart = moment(getFormattedTimezoneDate(offerPeriod?.starts, event?.timezone));
            const offerPeriodEnd = moment(getFormattedTimezoneDate(offerPeriod?.ends, event?.timezone));
            const periodStart = moment(getPeriodDate(period?.starts));
            const periodEnd = moment(getPeriodDate(period?.ends));
            const isExact = (periodStart.isSame(offerPeriodStart) && periodEnd.isSame(offerPeriodEnd));
            setIsExactMatch(isExact)
            console.log('is exxact', isExact);
            setMatchedOffers([offer])
            return isExact
        }

        if (onlyExact) {
            return isExactMatch(offer)

        } else if (onlyBetween) {
            return isInBetweenMatch(offer)
        } else {
            return (isExactMatch(offer) || isInBetweenMatch(offer))
        }
    }

    // open offers to display in create period modal and for in between matching availability period validation
    const getOpenOffers = (openOffers) => {
        console.log(openOffers);

        let currentOffer;

        // if editing, get all open offers that is not the offer that is editing  
        if (isEditing) {
            currentOffer = openOffers?.find(openOffer => openOffer.id === offer?.id)
        }

        // if editing offer, don't include that offer in array
        if (currentOffer) {
            console.log(currentOffer, currentOffer?.id, isEditing);
            openOffers = openOffers?.filter(offer => offer.id !== currentOffer?.id);
            console.log(openOffers);
        }

        // exclude ended offers 
        console.log(openOffers, event);
        openOffers = openOffers?.filter(offer => {
            const activePeriod = offer.availability.find(period => period.active);
            console.log(activePeriod);
            if (activePeriod) {
                // Keep the offer if it has an active period and hasn't ended
                return moment(getTimezoneDate(activePeriod.ends, event?.timezone)).isSameOrAfter(getTimezoneDate(moment(), event?.timezone))
            }
        })

        console.log(openOffers);

        return openOffers?.map(offer => {
            return {
                name: offer?.name,
                id: offer?.id,
                period: offer?.availability.find(period => period.active)
            }
        })
    }

    return (
        <>
            <div className="card-body-heading--sm card-body-heading--flex card-body-heading--flex-space-between">
                <div className='d-flex-column'>
                    <div className="flex">
                        <Card.Title as="h5" className='card-title-sm'>Availability</Card.Title>
                        {(!isStandard && (hasExactMatchError || hasInBetweenMatchError)) && (<RequiredText />)}
                    </div>
                    <Card.Subtitle as="h6" className="subtitle--dark">{getText()}</Card.Subtitle>
                </div>
                {!isEditing && (
                    <Button
                        variant="outline-light"
                        className="btn-plus"
                        onClick={handleShow}
                    >
                        Create period
                    </Button>
                )}
            </div>
            {(!isStandard && (isPeriodMatchingStandardOffer || isPeriodMatchingOtherOffer) && (<MatchWarningError willOverlapStandardAdmission={willOverlapStandardAdmission} periodStarts={offer?.availabilityPeriods?.find(period => period.id === selected)?.starts} periodEnds={offer?.availabilityPeriods?.find(period => period.id === selected)?.ends} hasExactError={hasExactMatchError} hasInBetweenError={hasInBetweenMatchError} matchedOffers={matchedOffers} timezone={event?.timezone} />))}
            {(isEditing && period) ? (
                <div className="offset-container-sm">
                    <Period period={period} timezone={event?.timezone} selected={period?.id} isEditing={isEditing} isDisabled={!canEdit} canEdit={canEdit && !period.name.includes('General On-sale')} status={offer?.status} handleShow={handleShow} />
                </div>
            ) : (
                <ul className='offset-container-sm'>
                    {periods?.map((period, index) => (
                        <Period key={index} period={period} timezone={event?.timezone} selected={selected} setSelected={setSelected} isEditing={isEditing} canEdit={index >= 2} canDelete={index >= 2} handleShow={handleShow} handleShowDelete={handleShowDelete} />
                    ))}
                </ul>
            )}
            {hasGeneralOnsalePeriod ? (
                <GeneralOnsaleModal show={showGeneralOnsale} event={event} eventStart={new Date(getFormattedTimezoneDate(event?.start, event?.timezone))} eventVisibility={event?.eventVisibility ? new Date(getFormattedTimezoneDate(event?.eventVisibility, event?.timezone)) : undefined} generalOnsale={generalOnsale} setGeneralOnsale={setGeneralOnsale} generalOnsaleEnd={new Date(getFormattedTimezoneDate(event?.end, event?.timezone))} error={generalOnsaleError} errorMsg={errorMsg} isDisabled={isEqual(generalOnsale, new Date(getFormattedTimezoneDate(event?.generalOnsale, event?.timezone)))} handleSubmit={handleSubmit} handleClose={handleCloseGeneralOnsale} />
            ) : (
                <CreatePeriodModal show={show} id={id} eventStart={new Date(getFormattedTimezoneDate(event?.start, event?.timezone))} eventEnd={new Date(getFormattedTimezoneDate(event?.end, event?.timezone))} eventVisibility={event?.eventVisibility ? new Date(getFormattedTimezoneDate(event?.eventVisibility, event?.timezone)) : undefined} openOffers={getOpenOffers(openOffers)} name={name} setName={setName} starts={starts} setStarts={setStarts} ends={ends} setEnds={setEnds} startError={startError} endError={endError} errorMsg={errorMsg} endErrorMsg={endErrorMsg} timezone={event?.timezone} isDisabled={checkIsDisabled()} handleSubmit={handleSubmit} handleClose={handleClose} isMatchingStandardOffer={isPeriodMatchingStandardOffer} willOverlapStandardAdmission={willOverlapStandardAdmission} isMatchingOtherOffer={isPeriodMatchingOtherOffer} matchedOffers={matchedOffers} hasExactMatchError={hasExactMatchError} hasInBetweenMatchError={hasInBetweenMatchError} />
            )}

            <DeleteModal show={showDelete} entity="availability period" handleDelete={handleDelete} handleClose={handleCloseDelete} />
        </>
    );
}