import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

import AuthService from '../../../utilities/services/auth.service';

import UserContext from '../../../context/User/User';
import { usePagination } from '../../../providers/PaginationProvider/PaginationProvider';

import { checkPermission } from "../../../utilities/helpers";

import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Stack from 'react-bootstrap/Stack';
import Card from 'react-bootstrap/Card';

import { SearchBar } from '../../SearchBar';
import { ExportBtn } from "../../ExportBtn";
import { LoadingContainer } from '../../LoadingContainer';
import { Holders } from './Holders';
import { Pagination } from '../../Pagination';

export default function HoldersList({ packageId, packageHolders }) {

    const { start, setStart, end, setEnd, currentPage, setCurrentPage, perPage, pageCount, setPageCount, resultsPerPageOpts, handleChange } = usePagination()

    const [isLoading, setIsLoading] = useState(false)

    // search query
    const [
        query,
        setQuery
    ] = useState('');

    // package holders with filters applied
    const [filteredPackageHolders, setFilteredPackageHolders] = useState(packageHolders);

    // set amount of pages
    useEffect(() => {
        const end = start + perPage;
        setEnd(end)
        const currentHolders = packageHolders?.data?.slice(start, end)
        setFilteredPackageHolders(currentHolders?.map(holder => holder?.attributes))
        setPageCount(Math.ceil(packageHolders?.data?.length / perPage))
    }, [packageHolders, start, perPage])

    // Invoke when user click to request another page.
    const handlePageClick = (e) => {
        const newStart = (e.selected * perPage) % packageHolders?.data?.length;
        setStart(newStart);
        setCurrentPage(e.selected)
    };

    return (
        <>
            <header className='section-header'>
                <div className="actions-group-flex">
                    <SearchBar getQuery={setQuery} placeholder="Search by phone number or email" />
                    <ExportBtn />
                </div>
            </header>
            {isLoading ? (
                <LoadingContainer />
            ) : (
                <>
                    {/* {filteredPackageHolders?.length > 0 ? ( */}
                    <Holders packageHolders={packageHolders} />
                    <Pagination pageCount={pageCount} perPage={perPage} currentPage={currentPage} resultsPerPageOpts={resultsPerPageOpts} handleClick={handlePageClick} handleChange={handleChange} />
                    {/* ) : (
                        <Card body>
                            <EmptyContainer style="center lg">
                                <p>No package holders found{query && ` matching '${query}'`}. Please try again.</p>
                            </EmptyContainer>
                        </Card>
                    )} */}
                </>
            )}
        </>
    );
}
